@font-face {
  font-family: 'KatyouBB';
  src: url('./assets/fonts/KatyouBB.ttf') format('truetype');
}

/* Global Styles */

body {
  font-family: Arial;
}

img {
  width: 100%;
}

button {
  cursor: pointer;
}

.flex-box {
 display: flex;
}

.buttons {
  font-weight: bold;
  color: white;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

/* Page */

.page-width {
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 1024px;
  overflow: hidden;
  margin: auto;
}

/* Banner */

.banner {
  min-width: 430px;
}

/* LanguageButton */

.language-button-div {
  align-self: flex-end;
  margin-top: 12px;
  margin-right: 20px;
}

.language-button {
  color: black;
  background-color: #FFFFFF;
  border: 1px solid #D1D5DB;
  margin: 0;
}

.language-button:hover {
  background-color: #F1F1F1;
}

/* Explanation */

.heading {
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 20px;
  margin-top: 4px;
}

.page-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
}

.explanation, .explanation-note {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.explanation {
  font-size: 16px;
}

.explanation-link {
  text-decoration: none;
  color: #3AB8B4;
}

.explanation-link:hover {
  text-decoration: underline;
  color: #019C3E;
}

.explanation-link:visited {
  color: #019C3E;
}

.explanation-link:active {
  color: #F9C200;
}

.explanation-note {
  font-size: 14px;
  margin: 0;
}

.poster-example-div {
  flex-direction: column;
  align-items: center;
}

.poster-example {
  width: 300px;
  height: 423px;
  border: 1px solid #BCC0C5;
}

.poster-example-note {
  font-size: 14px;
}

/* Form */

form {
  padding: 20px;
  padding-bottom: 0;
}

.form-div {
  flex-direction: column;
  align-items: center;
}

.form {
  max-width: 350px;
  margin-top: 20px;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 32px;
}

.form-heading {
  font-weight: 600;
  line-height: 24px;
}

.form-note {
  font-size: 12px;
  padding-left: 5px;
  margin: 0px;
}

.input-div {
}

.form-input {
  font-size: 100%;
  margin-top: 16px;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #BCC0C5;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  flex-grow: 1;
}

.form-input::placeholder {
  color: #7A8089;
}

textarea {
  font-family: Arial;
}

.design-options-div {
  justify-content: center;
  margin-top: 16px;
}

.design-options {
  max-width: 320px;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
}

/* RadioButton */

[type=radio] {
  display: none;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.design-choice-image {
  width: 150px;
  cursor: pointer;
  outline: 1px solid #9ca3af;
}

.design-choice-image:hover {
  outline: 3px solid #F9C200;
}

[type=radio]:checked + .design-choice-image {
  outline: 3px solid #019C3E;
}

/*-------------*/

.mirai::placeholder {
  line-height: 20px;
}

.age {
  max-width: 75px;
}

.image-question-div {
  flex-direction: column;
}

.image-note {
  align-content: center;
  height: 32px;
}

.selected-image-small {
  width: 250px;
  height: 140px;
  object-fit: cover;
}

.image-input-div {
  margin-top: 16px;
}

.image-input {
  font-size: 100%;
  max-width: 250px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.submit-button-div {
  justify-content: center;
}

.submit-button {
  background: #019C3E;
  padding-left: 40px;
  padding-right: 40px;
  border: 2px solid #01B246;
}

.submit-button:hover {
  background: #01B246;
}

/* ShareButtons */

.share-buttons {
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  column-gap: 8px;
  padding: 20px;
}

.share-buttons button {
  display: flex;
  align-items: center;
}

.share-info {
  width: 180px;
  font-size: 14px;
  margin: 0;
}

/* Output */

.result {
  margin-top: 24px;
}

.poster {

  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  padding: 20px;
}

.download-heading {
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.output-buttons {

  align-items: center;
  margin-bottom: 40px;
  column-gap: 20px;
}

.download-button {
  background: #019C3E;
  border: 2px solid #01B246;
}

.download-button:hover {
  background: #01B246;
}

.new-oshikatsu-button {
  background: #F9C200;
  border: 2px solid #FFCC1A;
}

.new-oshikatsu-button:hover {
  background: #FFCC1A;
}

.screenshot-div {
  display: grid;
  align-items: center;
}

.template-base, .download-div, .picture, .corner-tag, .poster-oshikatsu, .poster-mirai, .poster-name-age {
  grid-column: 1;
  grid-row: 1;
}

.picture, .corner-tag, .poster-oshikatsu, .poster-mirai, .poster-name-age, .download-div {
  position: relative;
}

.picture {
  object-fit: cover;
  height: 30.1%;
  width: 70.3%;
  bottom: 23.3%;
  left: 13.5%;
  border-radius: 0px 3px 3px 3px;
}

.corner-tag {
  max-width: 12.96%;
  bottom: 31.5%;
  left: 80.57%;
}

.poster-oshikatsu {
  font-family: 'KatyouBB', sans-serif;
  text-align: center;
  font-size: 4.4vw;
  font-weight: bold;
  white-space: nowrap;
  max-width: 40.3%;
  top: 6.2%;
  left: 27.1%;
  margin: 0;
}

.poster-mirai {
  font-size: 2.12vw;
  font-weight: bold;
  word-wrap: break-word;
  max-width: 43%;
  top: 24.5%;
  left: 12.5%;
  margin: 0;
}

.poster-name-age {
  font-size: 1.7vw;
  font-weight: bold;
  white-space: nowrap;
  max-width: 43%;
  top: 27.8%;
  left: 12.5%;
  margin: 0;
}

.download-div {
  width: 100%;
  height: 100%;
}

/* ScrollTopButton */

.scroll-top-button {
  position: fixed;
  bottom: 0;
  place-self: end;
  margin-bottom: 8px;
  padding: 0;
  border: none;
  background: none;
}

.scroll-top-image {
  height: 50px;
  width: 34px;
}

/* Footer */

.footer {

  justify-content: center;
  width: 100vw;
  background: #9CA3AF;
}

.footer-div {

  justify-content: space-around;
  align-items: center;
  margin: 20px;
  min-width: 300px;
  margin-right: 34px;
}

.footer-text {
  font-size: 12px;
  margin: 0;
}

.social-icons-div {

  column-gap: 8px;
}

footer a {
  width: 30px !important;
  height: 30px !important;
}

/* media queries */

@media (min-width: 410px) {
  .picture {
    border-radius: 0px 4px 4px 4px;
  }

  .poster-oshikatsu {
    font-size: 4.5vw;
  }
}

@media (min-width: 641px) {
  .picture {
    border-radius: 0px 6px 6px 6px;
  }

  .poster-oshikatsu {
    font-size: 4.6vw;
  }
}

@media (min-width: 811px) {
  .language-button-div {
    margin-right: 40px;
  }

  .picture {
    border-radius: 0px 7px 7px 7px;
  }

  .poster-oshikatsu {
    font-size: 4.7vw;
  }
}

@media (min-width: 1025px) {
  .banner, .scroll-button-div {
    width: 1024px;
  }

  .poster-oshikatsu {
    font-size: 300%;
  }

  .poster-mirai {
    font-size: 130%;
  }

  .poster-name-age {
    font-size: 110%
  }
}
